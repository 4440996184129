<template>
  <vx-card>
    <vs-prompt title="Export To Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
               accept-text="Export" @close="clearFields" :active.sync="activePrompt">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>

    <div class="export-table">
      <vs-table :data="users" search>

        <template slot="header">
          <vs-button @click="activePrompt=true">Export</vs-button>
        </template>

        <template slot="thead">
          <vs-th> Email</vs-th>
          <vs-th> Name</vs-th>
          <vs-th> Website</vs-th>
          <vs-th> Nro</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ data[indextr].email }}</vs-td>
            <vs-td>{{ data[indextr].name }}</vs-td>
            <vs-td>{{ data[indextr].website }}</vs-td>
            <vs-td>{{ data[indextr].id }}</vs-td>
          </vs-tr>
        </template>

      </vs-table>
    </div>
  </vx-card>
</template>

<script>
  import vSelect from 'vue-select'

  export default {
    components: {
      vSelect
    },
    data() {
      return {
        fileName: "",
        formats: ["xlsx", "csv", "txt"],
        cellAutoWidth: true,
        selectedFormat: "xlsx",
        headerTitle: ["Id", "Email", "Username", "Name", "Website"],
        headerVal: ["id", "email", "username", "name", "website"],
        users: [
          {
            "id": 1,
            "name": "Leanne Graham",
            "username": "Bret",
            "email": "Sincere@april.biz",
            "website": "hildegard.org",
          },
          {
            "id": 2,
            "name": "Ervin Howell",
            "username": "Antonette",
            "email": "Shanna@melissa.tv",
            "website": "anastasia.net",
          },
          {
            "id": 3,
            "name": "Clementine Bauch",
            "username": "Samantha",
            "email": "Nathan@yesenia.net",
            "website": "ramiro.info",
          },
          {
            "id": 4,
            "name": "Patricia Lebsack",
            "username": "Karianne",
            "email": "Julianne.OConner@kory.org",
            "website": "kale.biz",
          },
          {
            "id": 5,
            "name": "Chelsey Dietrich",
            "username": "Kamren",
            "email": "Lucio_Hettinger@annie.ca",
            "website": "demarco.info",
          },
          {
            "id": 6,
            "name": "Mrs. Dennis Schulist",
            "username": "Leopoldo_Corkery",
            "email": "Karley_Dach@jasper.info",
            "website": "ola.org",
          },
          {
            "id": 7,
            "name": "Kurtis Weissnat",
            "username": "Elwyn.Skiles",
            "email": "Telly.Hoeger@billy.biz",
            "website": "elvis.io",
          },
          {
            "id": 8,
            "name": "Nicholas Runolfsdottir V",
            "username": "Maxime_Nienow",
            "email": "Sherwood@rosamond.me",
            "website": "jacynthe.com",
          },
          {
            "id": 9,
            "name": "Glenna Reichert",
            "username": "Delphine",
            "email": "Chaim_McDermott@dana.io",
            "website": "conrad.com",
          },
          {
            "id": 10,
            "name": "Clementina DuBuque",
            "username": "Moriah.Stanton",
            "email": "Rey.Padberg@karina.biz",
            "website": "ambrose.net",
          },
        ],
        activePrompt: false,
      }
    },
    methods: {
      exportToExcel() {
        import('@/vendor/Export2Excel').then(excel => {
          const list = this.users;
          const data = this.formatJson(this.headerVal, list);
          excel.export_json_to_excel({
            header: this.headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat
          });
          this.clearFields()
        })
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j]
        }))
      },
      clearFields() {
        this.filename = "",
          this.cellAutoWidth = true,
          this.selectedFormat = "xlsx"
      }
    }
  }
</script>
